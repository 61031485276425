<template>
  <!-- backdrop -->
  <div class="contents">
    <slot></slot>
  </div>
</template>

<script setup>
const route = useRoute()
const reqURL = useRequestURL()
const { $gtagEvent } = useNuxtApp()

useAuthSync()
useTrackers()

watch(
  () => route.fullPath,
  () => {
    $gtagEvent.customPageView()
    process.client &&
      eS('sendEvent', 'PageView', {
        PageView: {}
      })
  }
)

onMounted(() => {
  $gtagEvent.customPageView()
})

// copy logic
const handleCopyEvent = event => {
  const selection = window.getSelection()
  const copiedText = selection.toString().trim()

  if (copiedText) {
    const selectedElement = selection?.anchorNode?.parentElement

    if (
      selectedElement?.id === 'variant-title' ||
      selectedElement?.id === 'variant-code' ||
      event?.target?.dataset?.copyProtection === '0' ||
      userIp === '193.254.216.198' ||
      userIp === '193.254.216.195'
    ) {
      return false
    }

    const additionalText = `\n\n\n Детальніше на ${reqURL.origin} \n ${
      reqURL.origin + route.path
    }`
    const newText = copiedText + additionalText

    event.clipboardData.setData('text/plain', newText)

    event.preventDefault()
  }
}

// ctrl + enter logic
const ctrlEnterModal = ref(null)

const openCtrlEnterModal = data => {
  ctrlEnterModal.value?.open(data)
}

const handleCtrlEnter = event => {
  if (event.ctrlKey && event.key === 'Enter') {
    const selection = window.getSelection().toString().trim()
    if (!selection || selection.rangeCount === 0) {
      return false
    }
    const pageURL = window.location.href

    openCtrlEnterModal({ link: pageURL, selected: selection })
  }
}
onMounted(() => {
  if (process.client) {
    document.addEventListener('copy', handleCopyEvent)
    document.addEventListener('keydown', handleCtrlEnter)
  }
})

onUnmounted(() => {
  if (process.client) {
    document.removeEventListener('copy', handleCopyEvent)
    document.removeEventListener('keydown', handleCtrlEnter)
  }
})
</script>
